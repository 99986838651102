/* eslint-disable max-lines */
import { Apollo, MutationResult, QueryRef } from 'apollo-angular';
import {
	AvailablePartnerResponse,
	ComplianceContent,
	ComponentReadAccess,
	ComponentWriteAccess,
	CreatePartnerRequest,
	PartType,
	PartTypeRequest,
	Partner,
	PartnerGroup,
	PartnerPartTypeReadAccess,
	ProductPassList,
	ProductPassTemplate,
	ProductPassTemplateFilter,
	ProductPassTemplateId,
	ProductPassTemplateJsonFile,
	ProductPassTemplateList,
	ProductPassTemplateState,
	UpdateComponentReadAccessRequest,
	UpdateComponentWriteAccessRequest,
	UpdatePartTypeReadAccessRequest,
	UpdatePartnerRelationResponse,
} from '@core/graphql/generated-types';
import {
	ComponentReadAccessResponse,
	ComponentWriteAccessResponse,
	PartnerGroupResponse,
	PartnerPartTypeReadAccessResponse,
	ProductPassListApiVariables,
	ProductPassTemplateListApiVariables,
} from './master-data.interface';
import { FetchPolicy, FetchResult } from '@apollo/client';
import {
	GQL_MUTATION_CREATE_PARTNER,
	GQL_MUTATION_CREATE_PARTNER_GROUP,
	GQL_MUTATION_CREATE_PART_TYPE,
	GQL_MUTATION_CREATE_PRODUCT_PASS_TEMPLATE,
	GQL_MUTATION_DELETE_PARTNER,
	GQL_MUTATION_DELETE_PARTNER_GROUP,
	GQL_MUTATION_DELETE_PART_TYPE,
	GQL_MUTATION_DELETE_PRODUCT_PASS_TEMPLATE,
	GQL_MUTATION_UPDATE_COMPONENT_READ_ACCESS,
	GQL_MUTATION_UPDATE_COMPONENT_WRITE_ACCESS,
	GQL_MUTATION_UPDATE_PARTNER_GROUP,
	GQL_MUTATION_UPDATE_PARTNER_PART_TYPE_READ_ACCESS,
	GQL_MUTATION_UPDATE_PARTNER_RELATION,
	GQL_MUTATION_UPDATE_PRODUCT_PASS_TEMPLATE,
	GQL_MUTATION_UPDATE_PRODUCT_PASS_TEMPLATE_STATE,
	GQL_QUERY_COMPLIANCE_CONTENT,
	GQL_QUERY_COMPONENT_READ_ACCESS,
	GQL_QUERY_COMPONENT_READ_ACCESS_BY_ID,
	GQL_QUERY_COMPONENT_WRITE_ACCESS,
	GQL_QUERY_CUSTOMERS_BY_PARTNER_ID,
	GQL_QUERY_GET_ALL_AVAILABLE_PARTNERS,
	GQL_QUERY_GET_AVAILABLE_SUPPLIERS,
	GQL_QUERY_PARTNER_GROUPS,
	GQL_QUERY_PARTNER_PART_TYPE_READ_ACCESS,
	GQL_QUERY_PART_TYPES,
	GQL_QUERY_PRODUCT_PASS_LIST,
	GQL_QUERY_PRODUCT_PASS_TEMPLATE_BY_ID,
	GQL_QUERY_PRODUCT_PASS_TEMPLATE_JSON_BY_ID,
	GQL_QUERY_PRODUCT_PASS_TEMPLATE_LIST,
} from './master-data-gql.constants';
import { Observable, map } from 'rxjs';

import { APOLLO_CLIENT_NAME } from '@core/enum/apollo-client-name.enum';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class MasterDataAdapterService {
	constructor(private apollo: Apollo) {}

	productPassTemplateListFilter: ProductPassTemplateFilter = {};

	getPartTypes(fetchPolicy: FetchPolicy): Observable<PartType[]> {
		return this.apollo
			.use(APOLLO_CLIENT_NAME.MASTER_DATA_MANAGEMENT)
			.query<{ getPartTypes: PartType[] }>({
				query: GQL_QUERY_PART_TYPES,
				fetchPolicy,
			})
			.pipe(map((result) => result.data.getPartTypes));
	}

	createPartType(partTypeName: string): Observable<MutationResult<unknown>> {
		const body: PartTypeRequest = {
			PartTypeName: partTypeName,
		};
		return this.apollo.use(APOLLO_CLIENT_NAME.MASTER_DATA_MANAGEMENT).mutate({
			mutation: GQL_MUTATION_CREATE_PART_TYPE,
			variables: {
				input: body,
			},
			update: (cache) => {
				cache.modify({
					fields: {
						getPartTypes(existingTaskRefs: PartType[]) {
							return [{ PartTypeName: partTypeName }, ...existingTaskRefs];
						},
					},
				});
			},
		});
	}

	deletePartType(partTypeName: string): Observable<MutationResult<unknown>> {
		const body: PartTypeRequest = {
			PartTypeName: partTypeName,
		};
		return this.apollo.use(APOLLO_CLIENT_NAME.MASTER_DATA_MANAGEMENT).mutate({
			mutation: GQL_MUTATION_DELETE_PART_TYPE,
			variables: {
				input: body,
			},
			update: (cache) => {
				cache.modify({
					fields: {
						getPartTypes(existingTaskRefs, { readField }) {
							return existingTaskRefs.filter((item: PartType) => readField('PartTypeName', item) !== partTypeName);
						},
					},
				});
			},
		});
	}

	getPartnerGroups(fetchPolicy: FetchPolicy): Observable<PartnerGroup[]> {
		return this.apollo
			.use(APOLLO_CLIENT_NAME.MASTER_DATA_MANAGEMENT)
			.watchQuery<{ getPartnerGroups: PartnerGroup[] }>({
				query: GQL_QUERY_PARTNER_GROUPS,
				fetchPolicy,
			})
			.valueChanges.pipe(map((result) => (result.data.getPartnerGroups ? result.data.getPartnerGroups : [])));
	}

	createPartnerGroup(partnerGroupName: string): Observable<PartnerGroup | null> {
		const body: PartnerGroup = {
			PartnerGroupName: partnerGroupName,
		};
		return this.apollo
			.use(APOLLO_CLIENT_NAME.MASTER_DATA_MANAGEMENT)
			.mutate<PartnerGroupResponse>({
				mutation: GQL_MUTATION_CREATE_PARTNER_GROUP,
				variables: { input: body },
				refetchQueries: [{ query: GQL_QUERY_PARTNER_GROUPS }],
			})
			.pipe(map((result) => (result.data?.createPartnerGroup ? result.data.createPartnerGroup : null)));
	}

	deletePartnerGroup(partnerGroupName: string): Observable<MutationResult<unknown>> {
		const body: PartnerGroup = {
			PartnerGroupName: partnerGroupName,
		};
		return this.apollo.use(APOLLO_CLIENT_NAME.MASTER_DATA_MANAGEMENT).mutate({
			mutation: GQL_MUTATION_DELETE_PARTNER_GROUP,
			variables: {
				input: body,
			},
			update: (cache) => {
				cache.modify({
					fields: {
						getPartnerGroups(existingTaskRefs, { readField }) {
							return existingTaskRefs.filter((item: PartnerGroup) => readField('PartnerGroupName', item) !== partnerGroupName);
						},
					},
				});
			},
		});
	}

	updatePartnerGroup(partnerGroupName: string, partTypes: string[]): Observable<MutationResult<unknown>> {
		const body: PartnerGroup = {
			PartnerGroupName: partnerGroupName,
			PartTypes: partTypes,
		};
		return this.apollo.use(APOLLO_CLIENT_NAME.MASTER_DATA_MANAGEMENT).mutate({
			mutation: GQL_MUTATION_UPDATE_PARTNER_GROUP,
			variables: {
				input: body,
			},
			update: (cache, result: FetchResult<{ updatePartnerGroup: PartnerGroup }>) => {
				cache.modify({
					fields: {
						getPartnerGroups(existingTaskRefs: PartnerGroup[], { readField }) {
							return existingTaskRefs.map((item: PartnerGroup) => {
								if (readField('PartnerGroupName', item) === partnerGroupName) {
									return { ...item, PartnerGroupName: result.data?.updatePartnerGroup.PartnerGroupName };
								}
								return item;
							});
						},
					},
				});
			},
		});
	}

	getAvailableCustomersBySupplierId(partnerId: string, fetchPolicy: FetchPolicy): Observable<Partner[]> {
		return this.apollo
			.use(APOLLO_CLIENT_NAME.MASTER_DATA_MANAGEMENT)
			.query<{ getAvailableCustomersBySupplierId: Partner[] }>({
				query: GQL_QUERY_CUSTOMERS_BY_PARTNER_ID,
				variables: { partnerId },
				fetchPolicy,
			})
			.pipe(map((result) => result.data.getAvailableCustomersBySupplierId));
	}

	getAvailableSuppliers(fetchPolicy: FetchPolicy): Observable<Partner[]> {
		return this.apollo
			.use(APOLLO_CLIENT_NAME.MASTER_DATA_MANAGEMENT)
			.query<{ getAvailableSuppliers: Partner[] }>({
				query: GQL_QUERY_GET_AVAILABLE_SUPPLIERS,
				fetchPolicy,
			})
			.pipe(map((result) => result.data.getAvailableSuppliers));
	}

	getAvailablePartner(fetchPolicy: FetchPolicy, includePermittedCustomers = false): Observable<AvailablePartnerResponse[]> {
		return this.apollo
			.use(APOLLO_CLIENT_NAME.MASTER_DATA_MANAGEMENT)
			.query<{ getAvailablePartner: AvailablePartnerResponse[] }>({
				query: GQL_QUERY_GET_ALL_AVAILABLE_PARTNERS,
				fetchPolicy,
				variables: { includePermittedCustomers },
			})
			.pipe(map((result) => result.data.getAvailablePartner));
	}
	partnerPartTypeReadAccessQueryRef(): QueryRef<PartnerPartTypeReadAccessResponse> {
		return this.apollo.use(APOLLO_CLIENT_NAME.MASTER_DATA_MANAGEMENT).watchQuery<PartnerPartTypeReadAccessResponse>({
			query: GQL_QUERY_PARTNER_PART_TYPE_READ_ACCESS,
			fetchPolicy: 'cache-and-network',
			notifyOnNetworkStatusChange: false,
		});
	}

	componentReadAccessQueryRef(): QueryRef<ComponentReadAccessResponse> {
		return this.apollo.use(APOLLO_CLIENT_NAME.MASTER_DATA_MANAGEMENT).watchQuery<ComponentReadAccessResponse>({
			query: GQL_QUERY_COMPONENT_READ_ACCESS,
			fetchPolicy: 'cache-and-network',
			notifyOnNetworkStatusChange: false,
		});
	}
	componentReadAccess(fetchPolicy: FetchPolicy): Observable<ComponentReadAccess[]> {
		return this.apollo
			.use(APOLLO_CLIENT_NAME.MASTER_DATA_MANAGEMENT)
			.query<ComponentReadAccessResponse>({
				query: GQL_QUERY_COMPONENT_READ_ACCESS,
				fetchPolicy,
			})
			.pipe(map((result) => result.data.componentReadAccess));
	}

	componentReadAccessById(contractId: string, fetchPolicy: FetchPolicy): Observable<ComponentReadAccess> {
		return this.apollo
			.use(APOLLO_CLIENT_NAME.MASTER_DATA_MANAGEMENT)
			.query<{ componentReadAccessById: ComponentReadAccess }>({
				query: GQL_QUERY_COMPONENT_READ_ACCESS_BY_ID,
				fetchPolicy,
				variables: { contractId },
			})
			.pipe(map((result) => result.data.componentReadAccessById));
	}

	componentWriteAccessQueryRef(): QueryRef<ComponentWriteAccessResponse> {
		return this.apollo.use(APOLLO_CLIENT_NAME.MASTER_DATA_MANAGEMENT).watchQuery<ComponentWriteAccessResponse>({
			query: GQL_QUERY_COMPONENT_WRITE_ACCESS,
			fetchPolicy: 'cache-and-network',
			notifyOnNetworkStatusChange: false,
		});
	}

	deletePartner(partnerId: string): Observable<MutationResult<unknown>> {
		return this.apollo.use(APOLLO_CLIENT_NAME.MASTER_DATA_MANAGEMENT).mutate({
			mutation: GQL_MUTATION_DELETE_PARTNER,
			variables: {
				partnerId,
			},
			// Remove value from success upon success
			update: (cache) => {
				cache.modify({
					fields: {
						getAvailablePartner(existingTaskRefs, { readField }) {
							return existingTaskRefs.filter((item: AvailablePartnerResponse) => readField('PartnerId', item) !== partnerId);
						},
					},
				});
			},
		});
	}
	updatePartnerRelation(partnerId: string, customers: string[]): Observable<MutationResult<unknown>> {
		return this.apollo.use(APOLLO_CLIENT_NAME.MASTER_DATA_MANAGEMENT).mutate({
			mutation: GQL_MUTATION_UPDATE_PARTNER_RELATION,
			variables: {
				partnerId,
				customers,
			},
			// Update permitted partners in cache
			update: (cache, result: FetchResult<{ updatePartnerRelation: UpdatePartnerRelationResponse }>) => {
				cache.modify({
					fields: {
						getAvailablePartner(existingTaskRefs: AvailablePartnerResponse[], { readField }) {
							return existingTaskRefs.map((item: AvailablePartnerResponse) => {
								if (readField('PartnerId', item) === partnerId) {
									return { ...item, PermittedCustomers: result.data?.updatePartnerRelation.PermittedCustomers };
								}
								return item;
							});
						},
					},
				});
			},
		});
	}

	createPartner(input: CreatePartnerRequest): Observable<MutationResult<unknown>> {
		return this.apollo.use(APOLLO_CLIENT_NAME.MASTER_DATA_MANAGEMENT).mutate({
			mutation: GQL_MUTATION_CREATE_PARTNER,
			variables: {
				PartnerId: input.PartnerId,
				PartnerName: input.PartnerName,
				PartnerLocation: input.PartnerLocation,
				PartnerType: input.PartnerType,
			},
			// Update permitted partners in cache
			update: (cache) => {
				cache.modify({
					fields: {
						getAvailablePartner(existingTaskRefs: AvailablePartnerResponse[]) {
							return [
								{
									PartnerId: input.PartnerId,
									PartnerName: input.PartnerName,
									PartnerLocation: input.PartnerLocation,
									PartnerType: input.PartnerType,
									PermittedCustomers: [],
								},
								...existingTaskRefs,
							];
						},
					},
				});
			},
		});
	}

	updatePartnerPartTypeReadAccess(
		partnerId: string,
		input: UpdatePartTypeReadAccessRequest,
	): Observable<MutationResult<{ updatePartnerPartTypeReadAccess: PartnerPartTypeReadAccess }>> {
		return this.apollo.use(APOLLO_CLIENT_NAME.MASTER_DATA_MANAGEMENT).mutate({
			mutation: GQL_MUTATION_UPDATE_PARTNER_PART_TYPE_READ_ACCESS,
			variables: {
				partnerId,
				input,
			},
			update: (cache, result: FetchResult<{ updatePartnerPartTypeReadAccess: PartnerPartTypeReadAccess }>) => {
				cache.modify({
					fields: {
						partnerPartTypeReadAccess(existingTaskRefs: PartnerPartTypeReadAccess[], { readField }) {
							return existingTaskRefs.map((item: PartnerPartTypeReadAccess) => {
								if (readField('PartnerId', item) === partnerId) {
									return { ...result.data?.updatePartnerPartTypeReadAccess };
								}
								return item;
							});
						},
					},
				});
			},
		});
	}

	updateComponentReadAccess(
		contractId: string,
		input: UpdateComponentReadAccessRequest,
	): Observable<MutationResult<{ updateComponentReadAccess: ComponentReadAccess }>> {
		return this.apollo.use(APOLLO_CLIENT_NAME.MASTER_DATA_MANAGEMENT).mutate({
			mutation: GQL_MUTATION_UPDATE_COMPONENT_READ_ACCESS,
			variables: { contractId, input },
			update: (cache, result: FetchResult<{ updateComponentReadAccess: ComponentReadAccess }>) => {
				cache.modify({
					fields: {
						componentReadAccess(existingTaskRefs: ComponentReadAccess[], { readField }) {
							return existingTaskRefs.map((item: ComponentReadAccess) => {
								if (readField('ContractId', item) === contractId) {
									return { ...result.data?.updateComponentReadAccess };
								}
								return item;
							});
						},
					},
				});
			},
		});
	}

	updateComponentWriteAccess(
		contractId: string,
		input: UpdateComponentWriteAccessRequest,
	): Observable<MutationResult<{ updateComponentWriteAccess: ComponentWriteAccess }>> {
		return this.apollo.use(APOLLO_CLIENT_NAME.MASTER_DATA_MANAGEMENT).mutate({
			mutation: GQL_MUTATION_UPDATE_COMPONENT_WRITE_ACCESS,
			variables: { contractId, input },
			update: (cache, result: FetchResult<{ updateComponentWriteAccess: ComponentWriteAccess }>) => {
				cache.modify({
					fields: {
						componentWriteAccess(existingTaskRefs: ComponentWriteAccess[], { readField }) {
							return existingTaskRefs.map((item: ComponentWriteAccess) => {
								if (readField('ContractId', item) === contractId) {
									return { ...result.data?.updateComponentWriteAccess };
								}
								return item;
							});
						},
					},
				});
			},
		});
	}

	getComplianceContent(fetchPolicy: FetchPolicy): Observable<ComplianceContent> {
		return this.apollo
			.use(APOLLO_CLIENT_NAME.MASTER_DATA_MANAGEMENT)
			.watchQuery<{ getComplianceContent: ComplianceContent }>({
				query: GQL_QUERY_COMPLIANCE_CONTENT,
				fetchPolicy,
				pollInterval: environment.config.home.complianceLinkPollingMS,
			})
			.valueChanges.pipe(map((result) => result.data.getComplianceContent));
	}

	productPassTemplate(variables: ProductPassTemplateListApiVariables): Observable<ProductPassTemplateList> {
		return this.apollo
			.use(APOLLO_CLIENT_NAME.MASTER_DATA_MANAGEMENT)
			.query<{ productPassTemplate: ProductPassTemplateList }, ProductPassTemplateListApiVariables>({
				query: GQL_QUERY_PRODUCT_PASS_TEMPLATE_LIST,
				variables,
				fetchPolicy: 'network-only',
			})
			.pipe(map((res) => res.data.productPassTemplate));
	}

	createProductPassTemplate(law: string, partType: string, description: string, jsonFileBase64Encoded: string): Observable<string> {
		return this.apollo
			.use(APOLLO_CLIENT_NAME.MASTER_DATA_MANAGEMENT)
			.mutate<{ createProductPassTemplate: { templateId: string } }>({
				mutation: GQL_MUTATION_CREATE_PRODUCT_PASS_TEMPLATE,
				variables: {
					law,
					partType,
					description,
					jsonFileBase64Encoded,
				},
			})
			.pipe(map((result) => result.data?.createProductPassTemplate.templateId as string));
	}

	productPassTemplateById(templateId: string, fetchPolicy: FetchPolicy): Observable<ProductPassTemplate> {
		return this.apollo
			.use(APOLLO_CLIENT_NAME.MASTER_DATA_MANAGEMENT)
			.query<{ productPassTemplateById: ProductPassTemplate }>({
				query: GQL_QUERY_PRODUCT_PASS_TEMPLATE_BY_ID,
				variables: { templateId },
				fetchPolicy,
			})
			.pipe(map((result) => result.data.productPassTemplateById));
	}

	productPassTemplateJson(templateId: string): Observable<ProductPassTemplateJsonFile> {
		return this.apollo
			.use(APOLLO_CLIENT_NAME.MASTER_DATA_MANAGEMENT)
			.query<{ productPassTemplateJson: ProductPassTemplateJsonFile }>({
				query: GQL_QUERY_PRODUCT_PASS_TEMPLATE_JSON_BY_ID,
				variables: { templateId },
				fetchPolicy: 'network-only',
			})
			.pipe(map((result) => result.data.productPassTemplateJson));
	}
	updateProductPassTemplate(templateId: string, jsonFileBase64Encoded: string, description: string): Observable<string> {
		return this.apollo
			.use(APOLLO_CLIENT_NAME.MASTER_DATA_MANAGEMENT)
			.mutate<{ updateProductPassTemplate: { templateId: string } }>({
				mutation: GQL_MUTATION_UPDATE_PRODUCT_PASS_TEMPLATE,
				variables: {
					templateId,
					jsonFileBase64Encoded,
					description,
				},
			})
			.pipe(map((result) => result.data?.updateProductPassTemplate.templateId as string));
	}
	deleteProductPassTemplate(templateId: string): Observable<ProductPassTemplateId | null> {
		return this.apollo
			.use(APOLLO_CLIENT_NAME.MASTER_DATA_MANAGEMENT)
			.mutate<{ deleteProductPassTemplate: { templateId: string } }>({
				mutation: GQL_MUTATION_DELETE_PRODUCT_PASS_TEMPLATE,
				variables: {
					templateId,
				},
				refetchQueries: [
					{
						query: GQL_QUERY_PRODUCT_PASS_TEMPLATE_LIST,
						variables: {
							where: this.productPassTemplateListFilter,
						},
					},
				],
			})
			.pipe(map((result) => (result.data && result.data?.deleteProductPassTemplate ? result.data.deleteProductPassTemplate : null)));
	}
	updateProductPassTemplateState(templateId: string, state: ProductPassTemplateState): Observable<ProductPassTemplateId | null> {
		return this.apollo
			.use(APOLLO_CLIENT_NAME.MASTER_DATA_MANAGEMENT)
			.mutate<{ updateProductPassTemplateState: { templateId: string } }>({
				mutation: GQL_MUTATION_UPDATE_PRODUCT_PASS_TEMPLATE_STATE,
				variables: {
					templateId,
					state,
				}, 
				refetchQueries: [
					{
						query: GQL_QUERY_PRODUCT_PASS_TEMPLATE_LIST,
						variables: {
							where: this.productPassTemplateListFilter,
						}
					},
					{
						query: GQL_QUERY_PRODUCT_PASS_TEMPLATE_BY_ID, 
						variables: {
							templateId,
							fetchPolicy: 'network-only'
						}
					}
				],
			})	
			.pipe(map((result) => (result.data && result.data?.updateProductPassTemplateState ? result.data.updateProductPassTemplateState : null)));
		}
		productPass(variables: ProductPassListApiVariables): Observable<ProductPassList> {
			return this.apollo
				.use(APOLLO_CLIENT_NAME.MASTER_DATA_MANAGEMENT)
				.query<{ productPass: ProductPassList }, ProductPassListApiVariables>({
					query: GQL_QUERY_PRODUCT_PASS_LIST,
					variables,
					fetchPolicy: 'network-only',
				})
				.pipe(map((res) => res.data.productPass));
		}
	}

