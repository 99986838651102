/* eslint-disable max-lines */
import {
	ComplianceContent,
	ComponentReadAccess,
	ComponentWriteAccess,
	CreatePartnerRequest,
	PartType,
	Partner,
	PartnerGroup,
	PartnerPartTypeReadAccess,
	ProductPassFilter,
	ProductPassList,
	ProductPassState,
	ProductPassTemplate,
	ProductPassTemplateFilter,
	ProductPassTemplateId,
	ProductPassTemplateJsonFile,
	ProductPassTemplateList,
	ProductPassTemplateState,
	ReadAccessRequest,
	UpdateComponentReadAccessRequest,
	UpdateComponentWriteAccessRequest,
	UpdatePartTypeReadAccessRequest,
} from '@core/graphql/generated-types';
import {
	ComponentReadAccessFilterValueKeys,
	ComponentReadAccessResponse,
	ComponentReadWriteAccessFilterValue,
	ComponentWriteAccessFilterValueKeys,
	ComponentWriteAccessResponse,
	PartTypeTableFilterValue,
	PartTypeTableFilterValueKeys,
	PartnerGroupTableFilterValue,
	PartnerGroupTableFilterValueKeys,
	PartnerPartTypeReadAccessFilterValue,
	PartnerPartTypeReadAccessFilterValueKeys,
	PartnerPartTypeReadAccessResponse,
	PermittedContractorsRelationsFilterValue,
	PermittedContractorsRelationsFilterValueKeys,
	ProductPassFilterValue,
	ProductPassFilterValueKeys,
	ProductPassListApiVariables,
	ProductPassTemplateCreateFormValue,
	ProductPassTemplateFilterValue,
	ProductPassTemplateFilterValueKeys,
	ProductPassTemplateListApiVariables,
} from './master-data.interface';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable, firstValueFrom, lastValueFrom } from 'rxjs';
import { first, last, trim } from 'lodash';

import { FetchPolicy } from '@apollo/client';
import { Injectable } from '@angular/core';
import { MasterDataAdapterService } from './master-data-adapter.service';
import { ParamMap } from '@angular/router';
import { QueryRef } from 'apollo-angular';
import { READ_ACCESS_EDIT_PROPERTY } from '../../enum/master-data.enum';
import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class MasterDataService {
	constructor(private masterDataAdapter: MasterDataAdapterService) {}

	async updatePartnerPartTypeReadAccess(partnerId: string, input: UpdatePartTypeReadAccessRequest): Promise<void> {
		await lastValueFrom(this.masterDataAdapter.updatePartnerPartTypeReadAccess(partnerId, input));
	}

	partnerPartTypeReadAccessQueryRef(): QueryRef<PartnerPartTypeReadAccessResponse> {
		return this.masterDataAdapter.partnerPartTypeReadAccessQueryRef();
	}

	componentReadAccessQueryRef(): QueryRef<ComponentReadAccessResponse> {
		return this.masterDataAdapter.componentReadAccessQueryRef();
	}

	async componentReadAccessByContractId(contractId: string, fetchPolicy: FetchPolicy = 'network-only'): Promise<ComponentReadAccess> {
		const res = await firstValueFrom(this.masterDataAdapter.componentReadAccessById(contractId, fetchPolicy));
		return res;
	}

	componentWriteAccessQueryRef(): QueryRef<ComponentWriteAccessResponse> {
		return this.masterDataAdapter.componentWriteAccessQueryRef();
	}

	async getAvailablePartner(fetchPolicy: FetchPolicy = 'network-only'): Promise<Partner[]> {
		const res = await lastValueFrom(this.masterDataAdapter.getAvailablePartner(fetchPolicy));
		return res as Partner[];
	}
	async getAvailablePartnerWithPermittedCustomers(fetchPolicy: FetchPolicy): Promise<Partner[]> {
		const res = await lastValueFrom(this.masterDataAdapter.getAvailablePartner(fetchPolicy, true));
		return res as Partner[];
	}
	/**
	 * @description Used for contract creation
	 */
	async getAvailableSuppliers(fetchPolicy: FetchPolicy = 'network-only'): Promise<Partner[]> {
		const res = await lastValueFrom(this.masterDataAdapter.getAvailableSuppliers(fetchPolicy));
		return res;
	}
	/**
	 * @description Used for contract creation
	 */
	async getAvailableCustomersBySupplierId(partnerId: string, fetchPolicy: FetchPolicy): Promise<Partner[]> {
		const res = await lastValueFrom(this.masterDataAdapter.getAvailableCustomersBySupplierId(partnerId, fetchPolicy));
		return res;
	}

	filterPartners(data: Partner[], filterValue: PermittedContractorsRelationsFilterValue, isSearch = false): Partner[] {
		if (!data || !Array.isArray(data)) {
			return data;
		}
		const filterPartnerId = trim((filterValue.partnerId as string)?.replaceAll('-', '')).toLowerCase();
		const filterPartnerName = trim(filterValue.partnerName as string)?.toLowerCase();
		if (!filterPartnerId && !filterPartnerName) {
			return data;
		}
		return data.filter((item) => {
			const itemPartnerName = (item.PartnerName as string).toLowerCase();
			const itemPartnerId = (item.PartnerId as string).toLowerCase();
			// Return match if value is included or filter is empty
			const hasPartnerIdMatch = filterPartnerId ? itemPartnerId.includes(filterPartnerId) : true;
			const hasPartnerNameMatch = filterPartnerName ? itemPartnerName.includes(filterPartnerName) : true;
			// Return AND condition filter
			return isSearch ? hasPartnerIdMatch || hasPartnerNameMatch : hasPartnerIdMatch && hasPartnerNameMatch;
		});
	}

	async getPartTypes(fetchPolicy: FetchPolicy): Promise<PartType[]> {
		return await lastValueFrom(this.masterDataAdapter.getPartTypes(fetchPolicy));
	}
	filterPartTypes(data: PartType[], filterValue: PartTypeTableFilterValue): PartType[] {
		if (!filterValue.partType) {
			return data;
		}
		const searchValues: string[] = filterValue.partType.split(environment.config.url.separator).map((value) => trim(value).toLocaleLowerCase());
		return data.filter((item) => {
			const itemPartType = (item.PartTypeName as string).toLowerCase();
			return searchValues.some((searchValue) => itemPartType.includes(searchValue));
		});
	}

	filterPartnerPartTypeReadAccess(
		data: PartnerPartTypeReadAccess[],
		filterValue: PartnerPartTypeReadAccessFilterValue,
		isSearch = false,
	): PartnerPartTypeReadAccess[] {
		if (!data || !Array.isArray(data)) {
			return data;
		}
		const filterPartnerId = trim((filterValue.partnerId as string).replaceAll('-', '')).toLowerCase();
		const filterPartnerName = trim(filterValue.partnerName as string).toLowerCase();
		if (!filterPartnerId && !filterPartnerName) {
			return data;
		}
		return data.filter((item) => {
			const itemPartnerName = (item.PartnerName as string).toLowerCase();
			const itemPartnerId = (item.PartnerId as string).toLowerCase();
			// Return match if value is included or filter is empty
			const hasPartnerIdMatch = filterPartnerId ? itemPartnerId.includes(filterPartnerId) : true;
			const hasPartnerNameMatch = filterPartnerName ? itemPartnerName.includes(filterPartnerName) : true;
			// Return AND condition filter
			return isSearch ? hasPartnerIdMatch || hasPartnerNameMatch : hasPartnerIdMatch && hasPartnerNameMatch;
		});
	}

	filterAccessRestriction(
		data: Array<ComponentReadAccess | ComponentWriteAccess>,
		filterValue: ComponentReadWriteAccessFilterValue,
	): Array<ComponentReadAccess | ComponentWriteAccess> {
		if (!data || !Array.isArray(data)) {
			return data;
		}
		const filterPartnerId = trim((filterValue.partner as string).replaceAll('-', '')).toLowerCase();
		const filterPartnerName = trim(filterValue.partner as string).toLowerCase();
		const filterContractId = trim(filterValue.contractId as string).toLowerCase();
		return data.filter((item) => {
			const itemPartnerName = (item.Partner?.PartnerName as string)?.toLowerCase();
			const itemPartnerId = (item.Partner?.PartnerId as string)?.toLowerCase();
			const itemContractId = (item.ContractId as string)?.toLowerCase();
			const hasPartnerIdMatch = filterPartnerId ? itemPartnerId?.includes(filterPartnerId) : true;
			const hasPartnerNameMatch = filterPartnerName ? itemPartnerName?.includes(filterPartnerName) : true;
			const hasContractIdMatch = filterContractId ? itemContractId?.includes(filterContractId) : true;
			return hasContractIdMatch && (hasPartnerIdMatch || hasPartnerNameMatch);
		});
	}

	filterReadAccessRestriction(data: ComponentReadAccess[], filterValue: ComponentReadWriteAccessFilterValue): ComponentReadAccess[] {
		return this.filterAccessRestriction(data, filterValue) as ComponentReadAccess[];
	}

	filterWriteAccessRestriction(data: ComponentWriteAccess[], filterValue: ComponentReadWriteAccessFilterValue): ComponentWriteAccess[] {
		return this.filterAccessRestriction(data, filterValue) as ComponentWriteAccess[];
	}

	async createPartType(partTypeName: string): Promise<void> {
		await firstValueFrom(this.masterDataAdapter.createPartType(partTypeName));
	}

	async deletePartType(partTypeName: string): Promise<void> {
		await lastValueFrom(this.masterDataAdapter.deletePartType(partTypeName));
	}

	async getAllPartnerGroups(fetchPolicy: FetchPolicy): Promise<PartnerGroup[]> {
		return await firstValueFrom(this.masterDataAdapter.getPartnerGroups(fetchPolicy));
	}

	getComplianceContent$(fetchPolicy: FetchPolicy): Observable<ComplianceContent> {
		return this.masterDataAdapter.getComplianceContent(fetchPolicy);
	}

	filterPartnerGroups(data: PartnerGroup[], filterValue: PartnerGroupTableFilterValue): PartnerGroup[] {
		const filteredPartnerGroup = filterValue.partnerGroup?.toLowerCase() || '';
		return data?.filter((item) => {
			if (!filteredPartnerGroup) {
				return true;
			}
			const itemPartnerGroup = (item.PartnerGroupName as string).toLowerCase();
			const hasPartnerGroupMatch = filteredPartnerGroup ? itemPartnerGroup.includes(filteredPartnerGroup) : true;

			return hasPartnerGroupMatch;
		});
	}

	async createPartnerGroup(partnerGroupName: string): Promise<void> {
		await lastValueFrom(this.masterDataAdapter.createPartnerGroup(partnerGroupName));
	}

	async deletePartnerGroup(partnerGroupName: string): Promise<void> {
		await lastValueFrom(this.masterDataAdapter.deletePartnerGroup(partnerGroupName));
	}

	async updatePartnerGroup(partnerGroupName: string, partTypes: string[]): Promise<void> {
		await lastValueFrom(this.masterDataAdapter.updatePartnerGroup(partnerGroupName, partTypes));
	}

	/**
	 * @description Performs sanitization by picking only relevant values from queryParams
	 */
	getPartTypeFilterQueryParams(paramMap: ParamMap): PartTypeTableFilterValue {
		// Define a type safe getter for the paramMap to prevent typos
		const typedGetFn = (name: PartTypeTableFilterValueKeys) => paramMap.get(name);
		return {
			partType: typedGetFn('partType') || '',
		};
	}
	getPartnerGroupFilterQueryParams(paramMap: ParamMap): PartnerGroupTableFilterValue {
		// Define a type safe getter for the paramMap to prevent typos
		const typedGetFn = (name: PartnerGroupTableFilterValueKeys) => paramMap.get(name);
		return {
			partnerGroup: typedGetFn('partnerGroup') || '',
		};
	}

	/**
	 * @description Performs sanitization by picking only relevant values from queryParams
	 */
	getPermittedContractorsRelationQueryParams(paramMap: ParamMap): PermittedContractorsRelationsFilterValue {
		// Define a type safe getter for the paramMap to prevent typos
		const typedGetFn = (name: PermittedContractorsRelationsFilterValueKeys) => paramMap.get(name);
		return {
			partnerId: typedGetFn('partnerId') || '',
			partnerName: typedGetFn('partnerName') || '',
		};
	}
	/**
	 * @description Performs sanitization by picking only relevant values from queryParams
	 */
	getPartnerPartyTypeReadAccessQueryParams(paramMap: ParamMap): PartnerPartTypeReadAccessFilterValue {
		// Define a type safe getter for the paramMap to prevent typos
		const typedGetFn = (name: PartnerPartTypeReadAccessFilterValueKeys) => paramMap.get(name);
		return {
			partnerId: typedGetFn('partnerId') || '',
			partnerName: typedGetFn('partnerName') || '',
		};
	}
	/**
	 * @description Performs sanitization by picking only relevant values from queryParams
	 */
	getComponentReadAccessQueryParams(paramMap: ParamMap): ComponentReadWriteAccessFilterValue {
		// Define a type safe getter for the paramMap to prevent typos
		const typedGetFn = (name: ComponentReadAccessFilterValueKeys) => paramMap.get(name);
		return {
			contractId: typedGetFn('contractId') || '',
			partner: typedGetFn('partner') || '',
		};
	}
	/**
	 * @description Performs sanitization by picking only relevant values from queryParams
	 */
	getComponentWriteAccessQueryParams(paramMap: ParamMap): ComponentReadWriteAccessFilterValue {
		// Define a type safe getter for the paramMap to prevent typos
		const typedGetFn = (name: ComponentWriteAccessFilterValueKeys) => paramMap.get(name);
		return {
			contractId: typedGetFn('contractId') || '',
			partner: typedGetFn('partner') || '',
		};
	}

	async deletePartner(partnerId: string): Promise<void> {
		await lastValueFrom(this.masterDataAdapter.deletePartner(partnerId));
	}

	async updatePartnerRelation(partnerId: string, customers: string[]): Promise<void> {
		await lastValueFrom(this.masterDataAdapter.updatePartnerRelation(partnerId, customers));
	}

	async createPartner(val: CreatePartnerRequest): Promise<void> {
		await lastValueFrom(this.masterDataAdapter.createPartner(val));
	}

	async updateComponentReadAccess(contractId: string, input: UpdateComponentReadAccessRequest): Promise<void> {
		await lastValueFrom(this.masterDataAdapter.updateComponentReadAccess(contractId, input));
	}

	async updateComponentWriteAccess(contractId: string, input: UpdateComponentWriteAccessRequest): Promise<void> {
		await lastValueFrom(this.masterDataAdapter.updateComponentWriteAccess(contractId, input));
	}

	getUpdateComponentReadAccessRequest(
		form: FormGroup<{ partners: FormControl<Partner[] | null>; partnerGroups: FormControl<PartnerGroup[] | null> }>,
		target: READ_ACCESS_EDIT_PROPERTY,
	): UpdateComponentReadAccessRequest {
		const readAccessRequest: ReadAccessRequest = {
			Partner: form.value.partners?.map((item) => item.PartnerId) as string[],
			PartnerGroup: form.value.partnerGroups?.map((item) => item.PartnerGroupName) as string[],
		};
		const input: UpdateComponentReadAccessRequest = {};
		switch (target) {
			case READ_ACCESS_EDIT_PROPERTY.ADDITIONAL_DATA: {
				input.AdditionalData = readAccessRequest;
				break;
			}
			case READ_ACCESS_EDIT_PROPERTY.COMPONENT_DETAILS: {
				input.ComponentDetail = readAccessRequest;
				break;
			}
		}
		return input;
	}

	async productPassTemplate(variables: ProductPassTemplateListApiVariables): Promise<ProductPassTemplateList> {
		const res = await firstValueFrom(this.masterDataAdapter.productPassTemplate(variables));
		return res;
	}
	serializeProductPassTemplateStateForQueryParams(selectedStates: ProductPassTemplateState[] | null | undefined): string | null {
		if (!selectedStates || selectedStates.length === 0) {
			return '';
		}
		return selectedStates.join(environment.config.url.separator);
	}
	deSerializeProductPassTemplateStateFromQueryParams(value: string): ProductPassTemplateState[] {
		if(!value) {
			return [];
		}
		return value?.split(environment.config.url.separator) as ProductPassTemplateState[];
	}

	getProductPassTemplateApiVariablesFromFilterValue(value: ProductPassTemplateFilterValue): ProductPassTemplateListApiVariables {
		const filter: ProductPassTemplateFilter = {
			...this.getLastModifiedApiVariablesFromFilterValue(value),
			...this.getLawAndPartTypeApiVariablesFromFilterValue(value),
			...this.getProductPassTemplateStateApiVariablesFromFilterValue(value),
		};
		return { where: filter };
	}

	getLastModifiedApiVariablesFromFilterValue(value: ProductPassTemplateFilterValue): Partial<ProductPassTemplateFilter> {
		const filter: Partial<ProductPassTemplateFilter> = {};
		if (value.lastModified) {
			filter.lastModifiedStartTime = `${first(value.lastModified.split(':') as unknown as string)}T00:00:00.000Z`;
			filter.lastModifiedEndTime = `${last(value.lastModified.split(':') as unknown as string)}T00:00:00.000Z`;
		}
		return filter;
	}
	getLawAndPartTypeApiVariablesFromFilterValue(value: ProductPassTemplateFilterValue): Partial<ProductPassTemplateFilter> {
		const filter: Partial<ProductPassTemplateFilter> = {};
		if (value.law) {
			filter.law = { _contains: value.law };
		}
		if (value.partType) {
			filter.partType = { _contains: value.partType };
		}
		return filter;
	}
	getProductPassTemplateStateApiVariablesFromFilterValue(value: ProductPassTemplateFilterValue): Partial<ProductPassTemplateFilter> {
		const filter: Partial<ProductPassTemplateFilter> = {};
		if (value.state && value.state.length > 0) {
			filter.state = { _in: this.getProductPassTemplateStateValueFromFilter(value.state) };
		}
		return filter;
	}
	getProductPassTemplateStateValueFromFilter(value: ProductPassTemplateState[]): ProductPassTemplateState[] {
		const stateValue = value.map((value) => value) as ProductPassTemplateState[];
		return stateValue;
	}
	getFilterValueFromQueryParams(paramMap: ParamMap, defaultFilterValue: ProductPassTemplateFilterValue): ProductPassTemplateFilterValue {
		const typedGetFn = (name: ProductPassTemplateFilterValueKeys) => paramMap.get(name);
		return {
			law: typedGetFn('law') ?? defaultFilterValue.law,
			lastModified: typedGetFn('lastModified') ?? defaultFilterValue.lastModified,
			state: this.deSerializeProductPassTemplateStateFromQueryParams(typedGetFn('state') as string) ?? defaultFilterValue.state,
			partType: typedGetFn('partType') ?? defaultFilterValue.partType,
		};
	}
	async createProductPassTemplate(value: ProductPassTemplateCreateFormValue): Promise<ProductPassTemplateId> {
		const productPassTemplateId = await lastValueFrom(
			this.masterDataAdapter.createProductPassTemplate(value.law, value.partType, value.description, value.jsonFileBase64Encoded),
		);
		return { templateId: productPassTemplateId as string };
	}
	async getProductPassTemplateById(templateId: string, fetchPolicy: FetchPolicy): Promise<ProductPassTemplate> {
		const res = await firstValueFrom(this.masterDataAdapter.productPassTemplateById(templateId, fetchPolicy));
		return res;
	}
	async getProductPassTemplateJsonById(templateId: string): Promise<ProductPassTemplateJsonFile> {
		return await firstValueFrom(this.masterDataAdapter.productPassTemplateJson(templateId));
	}
	async updateProductPassTemplate(templateId: string, jsonFileBase64Encoded: string, description: string): Promise<ProductPassTemplateId> {
		const productPassTemplateId = await lastValueFrom(this.masterDataAdapter.updateProductPassTemplate(templateId, jsonFileBase64Encoded, description));
		return { templateId: productPassTemplateId };
	}
	async deleteProductPassTemplate(productPassTemplateId: string): Promise<ProductPassTemplateId | null> {
		const res = await lastValueFrom(this.masterDataAdapter.deleteProductPassTemplate(productPassTemplateId));
		return res;
	}
	async updateProductPassTemplateState(templateId: string, state: ProductPassTemplateState): Promise<ProductPassTemplateId | null> {
		const productPassTemplateId = await lastValueFrom(this.masterDataAdapter.updateProductPassTemplateState(templateId,state));
		return productPassTemplateId;
	}
	async productPass(variables: ProductPassListApiVariables): Promise<ProductPassList> {
		const res = await firstValueFrom(this.masterDataAdapter.productPass(variables));
		return res;
	}
	getProductPassApiVariablesFromFilterValue(value: ProductPassFilterValue): ProductPassListApiVariables {
		const filter: ProductPassFilter = {
			...this.getProductPassLastModifiedApiVariablesFromFilterValue(value),
			...this.getProductPassStringApiVariablesFromFilterValue(value),
			...this.getProductPassStateApiVariablesFromFilterValue(value),
		};
		return { where: filter };
	}
	getProductPassLastModifiedApiVariablesFromFilterValue(value: ProductPassFilterValue): Partial<ProductPassFilter> {
		const filter: Partial<ProductPassFilter> = {};
		if (value.lastModified) {
			filter.lastModifiedStartTime = `${first(value.lastModified.split(':') as unknown as string)}T00:00:00.000Z`;
			filter.lastModifiedEndTime = `${last(value.lastModified.split(':') as unknown as string)}T00:00:00.000Z`;
		}
		return filter;
	}
	getProductPassStringApiVariablesFromFilterValue(value: ProductPassFilterValue): Partial<ProductPassFilter> {
		const filter: Partial<ProductPassFilter> = {};
		if (value.law) {
			filter.law = { _contains: value.law };
		}
		if (value.partType) {
			filter.partType = { _contains: value.partType };
		}
		if (value.partNumber) {
			filter.partNumber = { _contains: value.partNumber };
		}
		if (value.templateId) {
			filter.templateId = { _contains: value.templateId };
		}
		return filter;
	}
	getProductPassStateApiVariablesFromFilterValue(value: ProductPassFilterValue): Partial<ProductPassFilter> {
		const filter: Partial<ProductPassFilter> = {};
		if (value.state && value.state === 'Active') {
			filter.state = { _in: [ProductPassState.Active] };
		} else if (value.state && value.state === 'Inactive'){
			filter.state = { _in: [ProductPassState.Inactive] };
		}
		return filter;
	}
	// eslint-disable-next-line complexity
	getProductPassFilterValueFromQueryParams(paramMap: ParamMap, defaultFilterValue: ProductPassFilterValue): ProductPassFilterValue {
		const typedGetFn = (name: ProductPassFilterValueKeys) => paramMap.get(name);
		return {
			law: typedGetFn('law') ?? defaultFilterValue.law,
			lastModified: typedGetFn('lastModified') ?? defaultFilterValue.lastModified,
			state: typedGetFn('state') ?? defaultFilterValue.state,
			partType: typedGetFn('partType') ?? defaultFilterValue.partType,
			partNumber: typedGetFn('partNumber') ?? defaultFilterValue.partNumber,
			templateId: typedGetFn('templateId') ?? defaultFilterValue.templateId,
		};
	}
}
