import { ProductPassTemplateState } from "@core/graphql/generated-types";

export enum MASTER_DATA_ACTIONS {
	UPDATE = 'UPDATE',
	ADDITIONAL_DATA = 'ADDITIONAL_DATA',
	COMPONENT_DETAILS = 'COMPONENT_DETAILS',
	CREATE_PART_TYPE = 'CREATE_PART_TYPE',
	DELETE_PART_TYPE = 'DELETE_PART_TYPE',
	MANAGE_ASSIGNED_PARTNER_GROUP = 'MANAGE_ASSIGNED_PARTNER_GROUP',
	MANAGE_PART_TYPE_READ_PERMISSION_MANUALLY = 'MANAGE_PART_TYPE_READ_PERMISSION_MANUALLY',
	CREATE_PARTNER_GROUP = 'CREATE_PARTNER_GROUP',
	DELETE_PARTNER_GROUP = 'DELETE_PARTNER_GROUP',
	EDIT_PARTNER_GROUP_PART_TYPE_READ_PERMISSION = 'EDIT_PARTNER_GROUP_PART_TYPE_READ_PERMISSION',
	CREATE_PARTNER = 'CREATE_PARTNER',
	DELETE_PARTNER = 'DELETE_PARTNER',
	EDIT_PERMITTED_CUSTOMER = 'EDIT_PERMITTED_CUSTOMER',
	CREATE_PRODUCT_PASS_TEMPLATE = 'CREATE_PRODUCT_PASS_TEMPLATE',
	PRODUCT_PASS_TEMPLATE_DETAILS = 'PRODUCT_PASS_TEMPLATE_DETAILS',
	UPDATE_PRODUCT_PASS_TEMPLATE = 'UPDATE_PRODUCT_PASS_TEMPLATE',
	DELETE_PRODUCT_PASS_TEMPLATE = 'DELETE_PRODUCT_PASS_TEMPLATE',
	ACTIVATE_PRODUCT_PASS_TEMPLATE = 'ACTIVATE_PRODUCT_PASS_TEMPLATE',
	DEACTIVATE_PRODUCT_PASS_TEMPLATE = 'DEACTIVATE_PRODUCT_PASS_TEMPLATE',
	ARCHIVE_PRODUCT_PASS_TEMPLATE = 'ARCHIVE_PRODUCT_PASS_TEMPLATE',
	UPDATE_PRODUCT_PASS_TEMPLATE_STATE = 'UPDATE_PRODUCT_PASS_TEMPLATE_STATE'
}

export enum MASTER_DATA_RESOURCE {
	PRODUCT_PASS_TEMPLATE_BASIC_DATA = 'BASIC_DATA',
}

export enum READ_ACCESS_EDIT_PROPERTY {
	ADDITIONAL_DATA = 'ADDITIONAL_DATA',
	COMPONENT_DETAILS = 'COMPONENT_DETAILS',
}

export const MASTER_DATA_ACTION_PRODUCT_PASS_TEMPLATE_STATE_MAP: Partial<Record<ProductPassTemplateState, MASTER_DATA_ACTIONS>> = {
	[ProductPassTemplateState.Active]: MASTER_DATA_ACTIONS.ACTIVATE_PRODUCT_PASS_TEMPLATE,
	[ProductPassTemplateState.Inactive]: MASTER_DATA_ACTIONS.DEACTIVATE_PRODUCT_PASS_TEMPLATE,
	[ProductPassTemplateState.Archived]: MASTER_DATA_ACTIONS.ARCHIVE_PRODUCT_PASS_TEMPLATE,
}